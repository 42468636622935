import React from 'react'
import { Link } from 'gatsby'

const NotFoundPage = () => (
  <div>
    <h2>404 Not found</h2>
    <Link to="/">Go to home page</Link>
  </div>
)

export default NotFoundPage
